

<template>
<div>
  <el-form style="margin-top: 20px">
    <el-form-item v-for="(item, index) in data.items"  style="display: block;"  :key="index" :label="item.name"
                  :prop="item.key">
      <img style="width: 188px;height: 245px;" v-if="item.type == 'image'"  :src="item.url" alt="">
      <upImg :banner.sync="data.items[index][item.key]" :tipText="item.tips" v-if="item.type === 'file' && item.fileType == 'picture'">
      </upImg>
      <el-button v-if="item.type === 'file' && item.fileType == 'document'"  type="primary" size="small" class="" :disabled="!data.items[index][item.key]">
        <a v-if="data.items[index][item.key]"  target="_blank" :href="baseUrl+hostname+data.items[index][item.key]" style="color: #fff">
          立即下载
        </a>
<div v-else>立即下载</div>

        </el-button>

<div v-if="item.type=='radio'">
  {{data.items[index][item.key]}}
</div>
<!--      <upFile  :tip="item.tips" :value.sync="" ></upFile>-->
      <el-input v-model="data.items[index][item.key]" v-if="item.type == 'input'"
                :placeholder="item.tips"></el-input>

      <el-input v-model="data.items[index][item.key]" v-if="item.type == 'textarea'" type="textarea" :maxlength="item.wordLimit"></el-input>
      <div v-if="item.name=='礼品'" >
        <div >{{item.title}}<br></div>
        <div class="flex-align">
          <img style="width: 287px;height: 200px;margin-right: 20px"  :src="item.picture" alt="">
          <div style="margin-right: 20px">礼品名称：{{item.giftName}}</div>
          <div style="margin-right: 20px">数量：{{item.num?item.num:0}}</div>
        </div>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>
<script >
export default {
  props: {
    data: {
      type:Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {

    };
  },
  created() {
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_IMG_URL
    },
    hostname(){
      return process.env.VUE_APP_STATIC_DIR
    }
  },
  methods: {
  },
};
</script>
<style scoped lang="less">

</style>