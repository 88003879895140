<template>
<div class="exhibitor-configuration">
  <el-card>
    <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in menu" :label="item.name" :name="item.type" >
        <itemCpn  :data="item"></itemCpn>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</div>
</template>
<script>
import itemCpn from "@/views/tool/exhibitor/item-cpn.vue";
export default {

  data() {
    return {
      activeName:'first',
      exhibitionId:"",
      exhibitorId:"",
      menu:[]
    };
  },
  components:{
    itemCpn
  },
  created() {
    console.log(2313)
    this.exhibitionId= this.$route.query.exhibitionId
    this.exhibitorId= this.$route.query.exhibitorId
    console.log(this.exhibitionId,this.exhibitorId)
    this.getData()
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getData(){
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionConfig", {
        params: {
          exhibitionId: this.exhibitionId,
          exhibitorId: this.exhibitorId
        }
      })
      console.log(res)
      this.menu=res.data.menu
      this.activeName=res.data?.menu[0].type
    }
  },
};
</script>
<style scoped lang="less">

</style>